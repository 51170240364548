import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {environment} from '@env/environment';
// layout
import {LayoutDefaultComponent} from '../layout/default/default.component';
import {LayoutFullScreenComponent} from '../layout/fullscreen/fullscreen.component';
import {LayoutPassportComponent} from '../layout/passport/passport.component';
// dashboard pages
import { RedeemListComponent } from "./redeem/list/list.component";
// passport pages
import {UserLoginComponent} from './passport/login/login.component';
import {UserRegisterComponent} from './passport/register/register.component';
import {UserRegisterResultComponent} from './passport/register-result/register-result.component';
// single pages
import {CallbackComponent} from './backups/callback/callback.component';
import {UserLockComponent} from './passport/lock/lock.component';
import {Exception403Component} from './exception/403.component';
import {Exception404Component} from './exception/404.component';
import {Exception500Component} from './exception/500.component';


const routes: Routes = [
  {path: '', redirectTo: 'admin', pathMatch: 'full'},
  {
    path: 'admin',
    component: LayoutDefaultComponent,
    children: [
      {path: '', redirectTo: 'redeem', pathMatch: 'full'},
      {path: 'dashboard', redirectTo: 'dashboard/v1', pathMatch: 'full'},
      {path: 'authentication', loadChildren: './authentication/authentication.module#AuthenticationModule'},
      {path: 'logdoc', loadChildren: './logdoc/logdoc.module#LogdocModule'},
      {path: 'user', loadChildren: './usermanage/usermanage.module#UsermanageModule'},
      {path: 'vip', loadChildren: './vipmanage/vipmanage.module#VipmanageModule'},
      {path: 'deal', loadChildren: './deal/deal.module#DealModule'},
      {path: 'setting', loadChildren: './setting/setting.module#SettingModule'},
      {path: 'chart', loadChildren:'./chart/chart.module#ChartModule'},
      {path: 'advert', loadChildren:'./advert/advert.module#AdvertModule'},
      {path: 'redeem', loadChildren:'./redeem/redeem.module#RedeemModule'},
      {path: 'coupon', loadChildren:'./coupon/coupon.module#CouponModule'},
      {path: 'point', loadChildren:'./point/point.module#PointModule'},
      {path: 'story', loadChildren:'./story/story.module#StoryModule'},
      {path: 'sleep', loadChildren:'./story-sleep/story-sleep.module#StorySleepModule'},
      {path: 'rec', loadChildren:'./story-rec/story-rec.module#StoryRecModule'},
      {path: 'rate', loadChildren:'./story-rate/story-rate.module#StoryRateModule'},
    ],

  },
  // 全屏布局
  {
    path: 'data-v',
    component: LayoutFullScreenComponent,
    children: [
      {path: '', loadChildren: './backups/data-v/data-v.module#DataVModule'},
    ],
  },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'login',
        component: UserLoginComponent,
        data: {title: '登录'},
      },
      {
        path: 'register',
        component: UserRegisterComponent,
        data: {title: '注册'},
      },
      {
        path: 'register-result',
        component: UserRegisterResultComponent,
        data: {title: '注册结果'},
      },
    ],
  },
  // 单页不包裹Layout
  {path: 'callback/:type', component: CallbackComponent},
  {
    path: 'lock',
    component: UserLockComponent,
    data: {title: '锁屏', titleI18n: 'lock'},
  },
  {path: '403', component: Exception403Component},
  {path: '404', component: Exception404Component},
  {path: '500', component: Exception500Component},
  // { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: environment.useHash})],
  exports: [RouterModule],
})
export class RouteRoutingModule {
}
