const apiUrl = 'https://www.haichuanggu.com/API'; // (此处旧接口，暂时勿动，等全部修改完后可以删除)
const api = 'http://bms.tellstory.tppopp.com'; // 测试

export const environment = {
  SERVER_URL: `./`,
  production: false,
  useHash: true,
  hmr: false,

  /**
   * 接口地址(此处旧接口，暂时勿动，等全部修改完后可以删除)
   */
  api: {
    /**
     * 首页
     */
    home: {
      /**
       * 获取统计预览
       */
      getStatisticalPreview: `${apiUrl}/BMS/Home/getStatisticalPreview.do`,
      /**
       * 注册用户统计
       */
      getStatisticsUserList: `${apiUrl}/BMS/Home/getStatisticsUserList.do`,
      /**
       * 项目统计
       */
      getStatisticsProjectList: `${apiUrl}/BMS/Home/getStatisticsProjectList.do`,
      /**
       * 比赛和报名统计
       */
      getStatisticsMatchList: `${apiUrl}/BMS/Home/getStatisticsMatchList.do`,
      /**
       * 文章统计
       */
      getStatisticsArticleList: `${apiUrl}/BMS/Home/getStatisticsArticleList.do`,
      /**
       * 投资人和投资机构统计
       */
      getStatisticsInvest: `${apiUrl}/BMS/Home/getStatisticsInvest.do`,
      /**
       * 问题和答案统计
       */
      getStatisticsQuestionAnswer: `${apiUrl}/BMS/Home/getStatisticsQuestionAnswer.do`,
      /**
       * 培训和报名统计
       */
      getStatisticsTrainList: `${apiUrl}/BMS/Home/getStatisticsTrainList.do`,
    },
    /**
     *通用接口
     */
    common: {
      /**
       * 用户登录
       */
       
      /**
       * 获取用户信息
       */
      userInfo: `${apiUrl}/BMS/UserCore/userInfo.do`,
      /**
       * 退出登录
       */
      logout: `${apiUrl}/BMSLogin/logout.do`,
      /**
       * 上传文件
       */
      uploadFie: `${apiUrl}/BMS/UserCore/uploadFile.do`,
      /**
       * oss直传
       */
      signature: `${apiUrl}/api/Oss/signature.do`,
      /**
       * 获取消息列表
       */
      getNewsList: `${apiUrl}/BMS/UserCore/getNewsList.do`,
      /**
       * 获取全国地址列表
       */
      getChinaAddress: `${apiUrl}/Address/getChinaAddress.do`,
      /**
       * 获取手机地区
       */
      getMobileArea:`${apiUrl}/BMSLogin/getSMSRegions.do`
    },
  },

  /**
   * 接口地址(新接口写这里)
   */
  api2: {
    /**
     * 登录
     */
    common: {
      /**
       * 登录
       */
      login: `${api}/BMSLogin/login.do`,
      /**
       *  上传文件
       */
      uploadFile: `${api}/BMS/UserCore/uploadFile.do`,
      /**
       *  oss上传签名
       */
      signature: `${api}/BMS/Oss/signature.do`,
      /**
       *  获取验证码
       */
      getVCode: `${api}/open/getVCode.do`,
    },
    /**
     * 故事管理
     */
    story: {
      /**
       *  故事推送消息
       */
      pushStoryNotification: `${api}/BMS/StoryManage/pushStoryNotification.do`,
      /**
       *  设置推送模版状态
       */
      setPushTemplateStatus: `${api}/BMS/StoryManage/setPushTemplateStatus.do`,
      /**
       *  查看推送模版状态状态
       */
      getPushTemplateStatus: `${api}/BMS/StoryManage/getPushTemplateStatus.do`,
      /**
       * 故事列表list
       */
      getStoryList: `${api}/BMS/StoryManage/getStoryList.do`,
      /**
       *  添加故事
       */
      addStory: `${api}/BMS/StoryManage/addStory.do`,
      /**
       *  删除故事
       */
      deleteStory: `${api}/BMS/StoryManage/deleteStory.do`,
      /**
       *  编辑故事
       */
      updateStory: `${api}/BMS/StoryManage/updateStory.do`,
      /**
       *  上架或者下架故事 1:上架 2：下架
       */
      updateStoryStatus: `${api}/BMS/StoryManage/updateStoryStatus.do`,
      /**
       * 获取一级分类列表
       */
      getStoryPrimaryClass: `${api}/BMS/StoryManage/getStoryPrimaryClass.do`,
      /**
       *  修改故事一级分类名称
       */
      updateStoryPrimaryClass: `${api}/BMS/StoryManage/updateStoryPrimaryClass.do`,
      /**
       * 获取二级分类信息
       */
      getStorySecondaryClass: `${api}/BMS/StoryManage/getStorySecondaryClass.do`,
      /**
       *  获取故事集数列表
       */
      getStorySectionList: `${api}/BMS/StorySection/getStorySectionList.do`,
      /**
       *  上移故事集数
       */
      upStorySectionSort: `${api}/BMS/StorySection/upStorySectionSort.do`,
      /**
       *  下移故事集数
       */
      downStorySectionSort: `${api}/BMS/StorySection/downStorySectionSort.do`,
      /**
       *  删除故事集数
       */
      deleteStorySection: `${api}/BMS/StorySection/deleteStorySection.do`,
      /**
       *  添加故事章节
       */
      addStorySection: `${api}/BMS/StoryManage/addStorySection.do`,
      /**
       *  批量上传故事章节
       */
      batchAddStorySection: `${api}/BMS/StoryManage/batchAddStorySection.do`,
      /**
       * 获取故事的点赞列表
       */
      getStoryLikeRecord: `${api}/BMS/StoryRecord/getStoryLikeRecord.do`,
      /**
      *  获取用户的收藏列表
      */
      getStoryCollectRecord: `${api}/BMS/StoryRecord/getStoryCollectRecord.do`,
      /**
       *  获取故事的播放记录
       */
      getStoryPlayRecord: `${api}/BMS/StoryRecord/getStoryPlayRecord.do`,
      /**
      *  获取用户的下载列表
      */
      getStoryDownloadRecord: `${api}/BMS/StoryRecord/getStoryDownloadRecord.do`,
      /**
       *  获取用户的购买列表
       */
      getStoryPurchaseRecord: `${api}/BMS/StoryRecord/getStoryPurchaseRecord.do`,
      /**
       *  获取故事详情（上架，未上架，下架）
       */
      getStoryDetail: `${api}/BMS/StoryManage/getStoryDetail.do`,
      /**
       *  获取标签 input内
       */
      getStoryTag: `${api}/BMS/StoryManage/getStoryTag.do`,
      /**
       *  获取标签（有分页）
       */
      getPageStoryTag: `${api}/BMS/StoryManage/getPageStoryTag.do`,
      /**
       *  添加故事标签
       */
      addStoryTag: `${api}/BMS/StoryManage/addStoryTag.do`,
      /**
       *  删除故事标签
       */
      deleteStoryTag: `${api}/BMS/StoryManage/deleteStoryTag.do`,
      /**
       *  修改故事标签
       */
      updateStoryTag: `${api}/BMS/StoryManage/updateStoryTag.do`,
      /**
       *  获取叫早故事列表
       */
      getStoryMorningList: `${api}/BMS/StoryMorningSleepManage/getStoryMorningList.do`,
      /**
       *  获取哄睡故事列表
       */
      getStorySleepList: `${api}/BMS/StoryMorningSleepManage/getStorySleepList.do`,
      /**
       *  添加叫早故事
       */
      addMorningStory: `${api}/BMS/StoryMorningSleepManage/addMorningStory.do`,
      /**
       *  添加哄睡故事
       */
      addSleepStory: `${api}/BMS/StoryMorningSleepManage/addSleepStory.do`,
      /**
       *  删除叫早，哄睡故事
       */
      deleteMorningSleepStory: `${api}/BMS/StoryMorningSleepManage/deleteMorningSleepStory.do`,
      /**
       *  获取故事详情（哄睡，叫早）
       */
      getStorymoringDetail: `${api}/BMS/StoryMorningSleepManage/getStoryDetail.do`,
      /**
       *  编辑哄睡（叫早）故事
       */
      updateMorningSleepyStory: `${api}/BMS/StoryMorningSleepManage/updateMorningSleepyStory.do`,
      /**
       *  上移故事（哄睡，叫早）
       */
      upStorySort: `${api}/BMS/StoryMorningSleepManage/upStorySort.do`,
      /**
       *  下移故事（哄睡，叫早）
       */
      downStorySort: `${api}/BMS/StoryMorningSleepManage/downStorySort.do`,
      /**
       *  上架或者下架故事（哄睡，叫早）
       */
      updateStoryStatushh: `${api}/BMS/StoryMorningSleepManage/updateStoryStatus.do`,
      /**
       *  获取故事一级分类（带分页）
       */
      getPageStoryPrimaryClass: `${api}/BMS/StoryManage/getPageStoryPrimaryClass.do`,
      /**
       *  添加一级分类
       */
      addPrimaryClass: `${api}/BMS/StoryManage/addPrimaryClass.do`,
      /**
       *  删除一级分类
       */
      deletePrimaryClass: `${api}/BMS/StoryManage/deletePrimaryClass.do`,
      /**
       *  删除二级分类
       */
      deleteSecondClass: `${api}/BMS/StoryManage/deleteSecondClass.do`,
      /**
       *  添加故事二级分类
       */
      addSecondPrimaryClass: `${api}/BMS/StoryManage/addSecondPrimaryClass.do`,
      /**
       *  修改故事二级分类名称
       */
      updateStorySecondaryClass: `${api}/BMS/StoryManage/updateStorySecondaryClass.do`,
      /**
       *  获取二级分类信息(带分页)
       */
      getPageStorySecondaryClass: `${api}/BMS/StoryManage/getPageStorySecondaryClass.do`,
      /**
       *  获取专栏列表
       */
      getStoryColumnList: `${api}/BMS/StoryColumnManage/getStoryColumnList.do`,
      /**
       *  获取专栏详情
       */
      getStoryColumnDetail: `${api}/BMS/StoryColumnManage/getStoryColumnDetail.do`,
      /**
       *  获取专栏下面的故事列表
       */
      getStoryColumnStoryList: `${api}/BMS/StoryColumnManage/getStoryColumnStoryList.do`,
      /**
       *  显示专栏
       */
      showColumn: `${api}/BMS/StoryColumnManage/showColumn.do`,
      /**
       *  隐藏专栏
       */
      hideColumn: `${api}/BMS/StoryColumnManage/hideColumn.do`,
      /**
       *  编辑专栏
       */
      updateColumn: `${api}/BMS/StoryColumnManage/updateColumn.do`,

      /**
       *  添加专栏
       */
      addColumn: `${api}/BMS/StoryColumnManage/addColumn.do`,
      /**
       *  删除专栏
       */
      deleteColumn: `${api}/BMS/StoryColumnManage/deleteColumn.do`,
      /**
       *  删除专栏的故事
       */
      deleteColumnStory: `${api}/BMS/StoryColumnManage/deleteColumnStory.do`,
      /**
       *  获取专题列表
       */
      getStoryTopicList: `${api}/BMS/StoryTopicManage/getStoryTopicList.do`,
      /**
       *  获取专题下面的故事列表
       */
      getStoryTopicStoryList: `${api}/BMS/StoryTopicManage/getStoryTopicStoryList.do`,
      /**
       *  获取专题详情
       */
      getStoryTopicDetail: `${api}/BMS/StoryTopicManage/getStoryTopicDetail.do`,
      /**
       *  删除专题
       */
      deleteTopic: `${api}/BMS/StoryTopicManage/deleteTopic.do`,
      /**
       *  删除专题的故事
       */
      deleteTopicStory: `${api}/BMS/StoryTopicManage/deleteTopicStory.do`,
      /**
       *  显示专题
       */
      showTopic: `${api}/BMS/StoryTopicManage/showTopic.do`,
      /**
        *  隐藏专题
        */
       hideTopic: `${api}/BMS/StoryTopicManage/hideTopic.do`,
       /**
        *  编辑专题
        */
       updateTopic: `${api}/BMS/StoryTopicManage/updateTopic.do`,
       /**
        *  添加专题
        */
       addTopic: `${api}/BMS/StoryTopicManage/addTopic.do`,
      /**
       *  故事评价列表（1：待审核 2：已通过 3：未通过）
       */
      getStoryCommentList: `${api}/BMS/StoryComment/getStoryCommentList.do`,
      /**
       *  审核通过（不通过）
       */
      updateCommentStatus: `${api}/BMS/StoryComment/updateCommentStatus.do`,
      /**
       *  获取评论详情
       */
      getStoryCommentDetail: `${api}/BMS/StoryComment/getStoryCommentDetail.do`,
      /**
       *  获取故事适用年龄段
       */
      getBabyRangeList: `${api}/BMS/StoryManage/getBabyRangeList.do`,
      /**
       *  最近收藏，最近播放列表
       */
      getRecommendSettingsList: `${api}/BMS/RecommendSettingManage/getRecommendSettingsList.do`,
      /**
       *  添加收藏(最近播放)
       */
      addRecommendStory: `${api}/BMS/RecommendSettingManage/addRecommendStory.do`,
      /**
       *  删除（批量删除）收藏（最近播放）
       */
      deleteRecommendStory: `${api}/BMS/RecommendSettingManage/deleteRecommendStory.do`,
    },
    /**
     * 角色/权限管理
     */
    authentication: {
      /**
       * 获取角色列表
       */
      getRoteList: `${api}/BMS/Auth/getRoteList.do`,
      /**
       * 获取所有权限
       */
      getAllPermissions: `${api}/BMS/Auth/getAllPermissions.do`,
      /**
       * 根据角色ID获取拥有的权限
       */
      getRolePermissions: `${api}/BMS/Auth/getRolePermissions.do`,
      /**
       * 根据角色ID获取管理员列表
       */
      getRoteAdminList: `${api}/BMS/Auth/getRoteAdminList.do`,
      /**
       * 新增角色
       */
      insertRole: `${api}/BMS/Auth/insertRole.do`,
      /**
       * 编辑角色
       */
      editRole: `${api}/BMS/Auth/editRole.do`,
      /**
       * 删除角色
       */
      delRole: `${api}/BMS/Auth/delRole.do`,
      /**
       * 增加/删除角色权限
       */
      setRolePermission: `${api}/BMS/Auth/setRolePermission.do`,
      /**
       * 增加管理员帐号
       */
      addAdmin: `${api}/BMS/Auth/addAdmin.do`,
      /**
       * 编辑管理员帐号
       */
      editAdmin: `${api}/BMS/Auth/editAdmin.do`,
      /**
       * 更改管理员角色
       */
      setAdminRole: `${api}/BMS/Auth/setAdminRole.do`,
    },
    /**
     * 优惠券管理
     */
    CouponManage: {
      /**
       * 获取优惠券列表
       */
      getCouponList: `${api}/BMS/CouponManage/getCouponList.do`,
      /**
       * 添加优惠券
       */
      addCoupon: `${api}/BMS/CouponManage/addCoupon.do`,
      /**
       * 删除优惠券
       */
      delCoupon: `${api}/BMS/CouponManage/delCoupon.do`,
      /**
       * 获取优惠券设置
       */
      getCouponSetting: `${api}/BMS/CouponManage/getCouponSetting.do`,
      /**
       * 设置自动发送优惠券
       */
      setAutoreleaseCoupon: `${api}/BMS/CouponManage/setAutoreleaseCoupon.do`,
    },
    /**
     * 财务交易
     */
    OrderManage: {
      /**
       * 待支付的订单
       */
      getWaitPayOrderList: `${api}/BMS/OrderManage/getWaitPayOrderList.do`,
      /**
       * 已完成的订单
       */
      getCompletedOrderList: `${api}/BMS/OrderManage/getCompletedOrderList.do`,
      /**
       * 已取消的订单
       */
      getCancelOrderList: `${api}/BMS/OrderManage/getCancelOrderList.do`,
      /**
       * 获取流水记录
       */
      getTradesList: `${api}/BMS/OrderManage/getTradesList.do`,
      /**
       *  当前总交易金额
       */
      getTradesAmount: `${api}/BMS/OrderManage/getTradesAmount.do`,
    },
    /**
     * 积分管理
     */
    IntegralManage: {
      /**
       * 积分商品待发货订单
       */
      getIntegralOrderWaitDeliver: `${api}/BMS/IntegralManage/getIntegralOrderWaitDeliver.do`,
      /**
       * 订单发货
       */
      postOrderDeliver: `${api}/BMS/IntegralManage/postOrderDeliver.do`,
      /**
       * 积分商品已发货订单
       */
      getIntegralOrderAlreadyDeliver: `${api}/BMS/IntegralManage/getIntegralOrderAlreadyDeliver.do`,
      /**
       * 积分商品列表
       */
      getIntegralGoodList: `${api}/BMS/IntegralManage/getIntegralGoodList.do`,
      /**
       * 新增商品
       */
      AddGood: `${api}/BMS/IntegralManage/AddGood.do`,
      /**
       * 商品详情
       */
      getGoodDetail: `${api}/BMS/IntegralManage/getGoodDetail.do`,
      /**
       * 编辑商品
       */
      EditGood: `${api}/BMS/IntegralManage/EditGood.do`,
      /**
       * 上架/下架商品
       */
      changeGoodState: `${api}/BMS/IntegralManage/changeGoodState.do`,
      /**
       * 删除商品
       */
      deleteGood: `${api}/BMS/IntegralManage/deleteGood.do`,
      /**
       * 获取每日任务
       */
      getDailyTaskList: `${api}/BMS/IntegralManage/getDailyTaskList.do`,
      /**
       * 设置每日任务
       */
      setDailyTask: `${api}/BMS/IntegralManage/setDailyTask.do`,
      /**
       * 获取新手任务
       */
      getNoviceTaskList: `${api}/BMS/IntegralManage/getNoviceTaskList.do`,
      /**
       * 设置新手任务
       */
      setNoviceTask: `${api}/BMS/IntegralManage/setNoviceTask.do`,
      /**
       * 设置积分签到规则
       */
      setCheckInSettings: `${api}/BMS/IntegralManage/setCheckInSettings.do`,
      /**
       * 设置连续签到优惠券奖励
       */
      setContinueCheckInCoupon: `${api}/BMS/IntegralManage/setContinueCheckInCoupon.do`,
      /**
       * 查看签到设置
       */
      getCheckInSettings: `${api}/BMS/IntegralManage/getCheckInSettings.do`,
    },
    /**
     * 会员设置
     */
    Setting: {
      /**
       * 获取VIP协议
       */
      getVipAreement: `${api}/BMS/Setting/getVipAreement.do`,
      /**
       * 设置VIP协议
       */
      setVipAreement: `${api}/BMS/Setting/setVipAreement.do`,
      /**
       * 获取自动续费协议
       */
      getAutoRenewAreement: `${api}/BMS/Setting/getAutoRenewAreement.do`,
      /**
       * 设置自动续费协议
       */
      setAutoRenewAreement: `${api}/BMS/Setting/setAutoRenewAreement.do`,
      /**
       * 获取注册协议
       */
      getRegisterAreement: `${api}/BMS/Setting/getRegisterAreement.do`,
      /**
       * 设置注册协议
       */
      setRegisterAreement: `${api}/BMS/Setting/setRegisterAreement.do`,
      /**
       * 获取隐私协议
       */
      getPrivacyAreement: `${api}/BMS/Setting/getPrivacyAreement.do`,
      /**
       * 设置隐私协议
       */
      setPrivacyAreement: `${api}/BMS/Setting/setPrivacyAreement.do`,
      /**
       * 获取关于我们
       */
      getAboutUs: `${api}/BMS/Setting/getAboutUs.do`,
      /**
       * 设置关于我们
       */
      setAboutUs: `${api}/BMS/Setting/setAboutUs.do`,
      /**
       * 获取邀请有礼信息
       */
      getInvitationInfo: `${api}/BMS/Setting/getInvitationInfo.do`,
      /**
       * 设置邀请有礼信息
       */
      setInvitationInfo: `${api}/BMS/Setting/setInvitationInfo.do`,
      /**
       * 获取版本列表
       */
      getVersionList: `${api}/BMS/Setting/getVersionList.do`,
      /**
       * 上传APK文件
       */
      uploadApk: `${api}/BMS/Oss/uploadApk.do`,
      /**
       * 添加apk升级信息
       */
      addApkInfo: `${api}/BMS/Setting/addApkInfo.do`,
      /**
       * 获取全局收费设置
       */
      getGlobalFreeSetting: `${api}/BMS/Setting/getGlobalFreeSetting.do`,
      /**
       * 设置全局收费
       */
      setGlobalFreeSetting: `${api}/BMS/Setting/setGlobalFreeSetting.do`,
      /**
       * 获取客服电话
       */
      getServiceTelephone: `${api}/BMS/Setting/getServiceTelephone.do`,
      /**
       * 设置客服电话
       */
      setServiceTelephone: `${api}/BMS/Setting/setServiceTelephone.do`,
      /**
       * 获取赠送VIP天数
       */
      getDaysOfVIPGift: `${api}/BMS/Setting/getDaysOfVIPGift.do`,
      /**
       * 设置赠送VIP天数
       */
      setDaysOfVIPGift: `${api}/BMS/Setting/setDaysOfVIPGift.do`,
      /**
       * 查看会员费设置
       */
      getVipSettings: `${api}/BMS/VipSettingsManage/getVipSettings.do`,
      /**
       * 显示
       */
      showVipSettings: `${api}/BMS/VipSettingsManage/showVipSettings.do`,
      /**
       * 隐藏
       */
      hideVipSettings: `${api}/BMS/VipSettingsManage/hideVipSettings.do`,
      /**
       * 设置原价
       */
      setVipOriginPrice: `${api}/BMS/VipSettingsManage/setVipOriginPrice.do`,
      /**
       * 修改会员费设置
       */
      setVipType: `${api}/BMS/VipSettingsManage/setVipType.do`,
      /**
       *  获取推荐列表
       */
      getRecommendList: `${api}/BMS/RecommendManage/getRecommendList.do`,
      /**
       *  增加推荐信息
       */
      addRecommendInfo: `${api}/BMS/RecommendManage/addRecommendInfo.do`,
      /**
       *  编辑推荐信息
       */
      editRecommendInfo: `${api}/BMS/RecommendManage/editRecommendInfo.do`,
      /**
       *  删除推荐信息
       */
      deleteRecommendInfo: `${api}/BMS/RecommendManage/deleteRecommendInfo.do`,
      /**
       *  获取宝宝年龄范围
       */
      getBabyAgeRange: `${api}/BMS/RecommendManage/getBabyAgeRange.do`,
      /**
       *  问题反馈列表
       */
      getFeedbackList: `${api}/BMS/FeedbackManage/getFeedbackList.do`,
      /**
       *  反馈详情
       */
      getFeedbackDetail: `${api}/BMS/FeedbackManage/getFeedbackDetail.do`,
      /**
       *  回复问题
       */
      replyQuestion: `${api}/BMS/FeedbackManage/replyQuestion.do`,
    },
    /**
     * 用户管理
     */
    UserListManage: {
      /**
       * 用户列表
       */
      UserListManage: `${api}/BMS/UserListManage/UserList.do`,
      /**
       * 获取用户的baby列表
       */
      getUserBabyList: `${api}/BMS/UserDetailManage/getUserBabyList.do`,
      /**
       * 用户详细信息
       */
      getUserDetail: `${api}/BMS/UserDetailManage/getUserDetail.do`,
      /**
       * 收货地址
       */
      getUserReceivingAddress: `${api}/BMS/UserDetailManage/getUserReceivingAddress.do`,
      /**
       *  获取用户邀请奖励
       */
      getUserInvitationAward: `${api}/BMS/UserDetailManage/getUserInvitationAward.do`,
      /**
       * 用户积分明细
       */
      getUserPointsDetail: `${api}/BMS/UserDetailManage/getUserPointsDetail.do`,
      /**
       * 用户积分兑换记录
       */
      getUserPointOrder: `${api}/BMS/UserDetailManage/getUserPointOrder.do`,
      /**
       * 订单记录
       */
      getUserOrder: `${api}/BMS/UserDetailManage/getUserOrder.do`,
      /**
       * 冻结账号
       */
      freezeUser: `${api}/BMS/UserListManage/freezeUser.do`,
      /**
       * 解冻账号
       */
      unfreezeUser: `${api}/BMS/UserListManage/unfreezeUser.do`,
      /**
       * 添加用户
       */
      addUser: `${api}/BMS/UserListManage/addUser.do`,
      /**
       *  兑换码记录
       */
      getUserRedeemCode: `${api}/BMS/UserDetailManage/getUserRedeemCode.do`,
      /**
       *  查看用户消息
       */
      getUserMessageList: `${api}/BMS/UserDetailManage/getUserMessageList.do`,
      /**
       * 添加消息
       */
      addUserMessage: `${api}/BMS/UserDetailManage/addUserMessage.do`,
    },
    /**
     * 广告管理
     */
    AdvantageManage: {
      /**
       * banner广告列表
       */
      getBannerList: `${api}/BMS/AdvantageManage/getBannerList.do`,
      /**
       * 开屏广告列表
       */
      getOpenList: `${api}/BMS/AdvantageManage/getOpenList.do`,
      /**
       * 弹窗广告列表
       */
      getPopList: `${api}/BMS/AdvantageManage/getPopList.do`,
      /**
       * 横条广告列表
       */
      getHorizontalList: `${api}/BMS/AdvantageManage/getHorizontalList.do`,
      /**
       * 漂浮礼物广告列表
       */
      getGiftList: `${api}/BMS/AdvantageManage/getGiftList.do`,
      /**
       * 滑动广告列表
       */
      getSlideList: `${api}/BMS/AdvantageManage/getSlideList.do`,
      /**
       * 添加banner广告
       */
      addBannerAd: `${api}/BMS/AdvantageManage/addBannerAd.do`,
      /**
       * 添加开屏广告
       */
      addOpenAd: `${api}/BMS/AdvantageManage/addOpenAd.do`,
      /**
       * 添加弹窗广告
       */
      addPopAd: `${api}/BMS/AdvantageManage/addPopAd.do`,
      /**
       * 添加横条广告
       */
      addHorizontalAd: `${api}/BMS/AdvantageManage/addHorizontalAd.do`,
      /**
       * 添加漂浮广告
       */
      addGiftAd: `${api}/BMS/AdvantageManage/addGiftAd.do`,
      /**
       * 添加滑动广告
       */
      addSlideAd: `${api}/BMS/AdvantageManage/addSlideAd.do`,
      /**
       * 查看开屏广告禁用启用状态
       */
      getOpenAdStatus: `${api}/BMS/AdvantageManage/getOpenAdStatus.do`,
      /**
       * 查看弹窗广告禁用启用状态
       */
      getPopAdStatus: `${api}/BMS/AdvantageManage/getPopAdStatus.do`,
      /**
       * 查看滑动广告禁用启用状态
       */
      getSlideAdStatus: `${api}/BMS/AdvantageManage/getSlideAdStatus.do`,
      /**
       * 查看横条广告禁用启用状态
       */
      getHorizontalAdStatus: `${api}/BMS/AdvantageManage/getHorizontalAdStatus.do`,
      /**
       * 查看弹窗广告禁用启用状态
       */
      getGiftAdStatus: `${api}/BMS/AdvantageManage/getGiftAdStatus.do`,
      /**
       * 启用（或禁用）开屏广告
       */
      setOpenAdStatus: `${api}/BMS/AdvantageManage/setOpenAdStatus.do`,
      /**
       * 启用（或禁用）横条广告
       */
      setHorizontalAdStatus: `${api}/BMS/AdvantageManage/setHorizontalAdStatus.do`,
      /**
       * 启用（或禁用）滑动广告
       */
      setSlideAdStatus: `${api}/BMS/AdvantageManage/setSlideAdStatus.do`,
      /**
       * 启用（或禁用）漂浮广告
       */
      setGiftAdStatus: `${api}/BMS/AdvantageManage/setGiftAdStatus.do`,
      /**
       * 启用（或禁用）弹窗广告
       */
      setPopAdStatus: `${api}/BMS/AdvantageManage/setPopAdStatus.do`,
      /**
       * 删除开屏广告
       */
      deleteOpenAd: `${api}/BMS/AdvantageManage/deleteOpenAd.do`,
      /**
       * 删除横条广告
       */
      deleteHorizontalAd: `${api}/BMS/AdvantageManage/deleteHorizontalAd.do`,
      /**
       * 删除漂浮广告
       */
      deleteGiftAd: `${api}/BMS/AdvantageManage/deleteGiftAd.do`,
      /**
       * 删除弹窗广告
       */
      deletePopAd: `${api}/BMS/AdvantageManage/deletePopAd.do`,
      /**
       * 删除滑动广告
       */
      deleteSlideAd: `${api}/BMS/AdvantageManage/deleteSlideAd.do`,
      /**
       * 删除banner广告
       */
      deleteBannerAd: `${api}/BMS/AdvantageManage/deleteBannerAd.do`,
      /**
       * 显示广告
       */
      showAd: `${api}/BMS/AdvantageManage/showAd.do`,
      /**
       * 隐藏广告
       */
      hideAd: `${api}/BMS/AdvantageManage/hideAd.do`,
      /**
       * 编辑banner广告
       */
      updateBannerAd: `${api}/BMS/AdvantageManage/updateBannerAd.do`,
      /**
       * banner广告
       */
      getBannerDetail: `${api}/BMS/AdvantageManage/getBannerDetail.do`,
      /**
       * 编辑开屏广告
       */
      updateOpenAd: `${api}/BMS/AdvantageManage/updateOpenAd.do`,
      /**
       * 开屏广告
       */
      getOpenDetail: `${api}/BMS/AdvantageManage/getOpenDetail.do`,
      /**
       * 编辑弹窗广告
       */
      updatePopAd: `${api}/BMS/AdvantageManage/updatePopAd.do`,
      /**
       * 弹窗广告
       */
      getPopDetail: `${api}/BMS/AdvantageManage/getPopDetail.do`,
      /**
       * 编辑横条广告
       */
      updateHorizontalAd: `${api}/BMS/AdvantageManage/updateHorizontalAd.do`,
      /**
       * 横条广告
       */
      getHorizontalDetail: `${api}/BMS/AdvantageManage/getHorizontalDetail.do`,
      /**
       * 编辑漂浮广告
       */
      updateGiftAd: `${api}/BMS/AdvantageManage/updateGiftAd.do`,
      /**
       * 漂浮广告
       */
      getGiftDetail: `${api}/BMS/AdvantageManage/getGiftDetail.do`,
      /**
       * 编辑滑动广告
       */
      updateSlideAd: `${api}/BMS/AdvantageManage/updateSlideAd.do`,
      /**
       * 滑动广告
       */
      getSlideDetail: `${api}/BMS/AdvantageManage/getSlideDetail.do`,
      /**
       * 广告类目
       */
      getAdInternalJumpList: `${api}/BMS/AdvantageManage/getAdInternalJumpList.do`,
    },
    /**
     * 数据统计
     */
    DataStatistics: {
      /**
       * 故事播放排名
       */
      getStoryPayRanking: `${api}/BMS/DataStatistics/getStoryPayRanking.do`,
      /**
       * 故事购买排名
       */
      getStoryBuyRanking: `${api}/BMS/DataStatistics/getStoryBuyRanking.do`,
      /**
       * 广告位流量统计
       */
      getAdFlowStatistics: `${api}/BMS/DataStatistics/getAdFlowStatistics.do`,
    },
    /**
     * 兑换码管理
     */
    IntegralManage2: {
      /**
       * 兑换码列表
       */
      getRedeemCodeList: `${api}/BMS/IntegralManage/getRedeemCodeList.do`,
      /**
       * 批量生成兑换码
       */
      generateRedeemCode: `${api}/BMS/IntegralManage/generateRedeemCode.do`,
      /**
       * 过期兑换码
       */
      overdueredeemCode: `${api}/BMS/IntegralManage/overdueredeemCode.do`,
      /**
       * 删除兑换码
       */
      deleteRedeemCode: `${api}/BMS/IntegralManage/deleteRedeemCode.do`,
      /**
       * 兑换码详情
       */
      getRedeemCodeDetail: `${api}/BMS/IntegralManage/getRedeemCodeDetail.do`,
    },
  },

  /**
   * 菜单配置
   */
  'menu': [
    {
      'text': '主导航',
      'i18n': 'menu.main',
      'group': false,
      'hideInBreadcrumb': true,
      'children': [
        // {
        //   'text': '首页',
        //   'link': '/admin/dashboard',
        //   'icon': 'anticon anticon-appstore'
        // },
        {
          'text': '运营管理',
          'icon': 'anticon anticon-bars',
          'children': [
            {
              'text': '广告管理',
              'link': '/admin/advert/banner',
            },
            {
              'text': '兑换码',
              'link': '/admin/redeem/list',
            },
            {
              'text': '优惠券管理',
              'link': '/admin/coupon/list',
            },
          ]
        },
        {
          'text': '故事管理',
          'icon': 'anticon anticon-book',
          'children': [
            {
              'text': '故事列表',
              'link': '/admin/story/list',
            },
            {
              'text': '哄睡列表',
              'link': '/admin/sleep/list',
            },
            {
              'text': '叫早列表',
              'link': '/admin/sleep/earlylist',
            },
            {
              'text': '故事分类',
              'link': '/admin/story/classify',
            },
            {
              'text': '故事标签',
              'link': '/admin/story/label',
            },
            {
              'text': '故事专栏',
              'link': '/admin/story/column',
            },
            {
              'text': '故事专题',
              'link': '/admin/story/subject',
            },
            {
              'text': '故事评价',
              'link': '/admin/rate/check',
            },
            {
              'text': '推荐设置',
              'link': '/admin/rec/collection',
            },
          ]
        },
        {
          'text': '财务管理',
          'icon': 'anticon anticon-bank',
          'children': [
            {
              'text': '订单管理',
              'link': '/admin/deal/list',
            },
            {
              'text': '流水明细',
              'link': '/admin/deal/detail',
            },
          ]
        },
        {
          'text': '积分管理',
          'icon': 'anticon anticon-trademark',
          'children': [
            {
              'text': '积分兑换记录',
              'link': '/admin/point/mail',
            },
            {
              'text': '积分商城',
              'link': '/admin/point/shop',
            },
            {
              'text': '积分设置',
              'link': '/admin/point/set',
            },
          ]
        },
        {
          'text': '会员设置',
          'icon': 'anticon anticon-idcard',
          'children': [
            {
              'text': '会员费设置',
              'link': '/admin/vip/vipcost',
            },
            {
              'text': '会员协议',
              'link': '/admin/vip/vipagreement',
            },
            // {
            //   'text': '自动续费协议',
            //   'link': '/admin/vip/autoagreement',
            // },
          ]
        },
        {
          'text': '数据统计',
          'icon': 'anticon anticon-area-chart',
          'children': [
            // {
            //   'text': '在线人数',
            //   'link': '/admin/chart/online',
            // },
            {
              'text': '播放次数',
              'link': '/admin/chart/playtimes',
            },
            {
              'text': '畅销排行榜',
              'link': '/admin/chart/sell',
            },
            {
              'text': '广告位流量',
              'link': '/admin/chart/advertisement',
            },
            // {
            //   'text': 'app下载流量',
            //   'link': '/admin/chart/app',
            // },
          ]
        },
        {
          'text': '用户管理',
          'icon': 'anticon anticon-bank',
          'children': [
            {
              'text': '用户列表',
              'link': '/admin/user/list',
            },
            {
              'text': '用户消息',
              'link': '/admin/user/notice',
            },
          ]
        },
        {
          'text': '系统设置',
          'icon': 'anticon anticon-setting',
          'children': [
            {
              'text': '基本设置',
              'link': '/admin/setting/alipay',
            },
            {
              'text': '智能推荐',
              'link': '/admin/setting/recommend',
            },
            {
              'text': '版本更新',
              'link': '/admin/setting/version',
            },
            {
              'text': '问题反馈',
              'link': '/admin/setting/question',
            },
          ]
        },
        {
          'text': '权限管理',
          'link': '/admin/authentication',
          'icon': 'anticon anticon-safety'
        },
        // {
        //   'text': '日志',
        //   'group': true,
        //   'icon': 'anticon anticon-exception',
        //   'children': [
        //     {
        //       'text': '软件日志',
        //       'link': '/admin/logdoc',
        //     },
        //   ],
        // },
      ]
    }
  ]
};
