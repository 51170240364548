import {Component, OnInit} from '@angular/core';
import { _HttpClient } from '@delon/theme';
import 'zone.js';
import 'reflect-metadata';
import * as DataSet from '@antv/data-set';
import {environment} from "@env/environment.hmr";
import {NzMessageService} from "ng-zorro-antd";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard-v1',
  templateUrl: './v1.component.html',
  styleUrls: ['./v1.component.less'],
})
export class DashboardV1Component implements OnInit {
  style = { stroke: '#fff', lineWidth: 1 };
  forceFit = true;
  height = 300;
  totalScale = [{
    dataKey: 'x',
    min: 0,
    max: 1,
  }];

  info;

  StatisticsUserList;
  StatisticsUserType = 1;
  StatisticsUserTime = ['', ''];

  StatisticsProjectList;
  StatisticsProjectType = 1;
  StatisticsProjectTime = ['', ''];

  StatisticsMatchList;
  StatisticsMatchType = 1;
  StatisticsMatchTime = ['', ''];

  StatisticsArticleList;
  StatisticsArticleType = 1;
  StatisticsArticleTime = ['', ''];

  StatisticsInvestList;
  StatisticsInvestType = 1;
  StatisticsInvestTime = ['', ''];

  StatisticsQuestionAnswerList;
  StatisticsQuestionAnswerType = 1;
  StatisticsQuestionAnswerTime = ['', ''];

  StatisticsTrainList;
  StatisticsTrainType = 1;
  StatisticsTrainTime = ['', ''];

  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.getStatisticalPreview();

    this.getStatisticsUserList();
    this.getStatisticsProjectList();
    this.getStatisticsMatchList();
    this.getStatisticsArticleList();
    this.getStatisticsInvest();
    this.getStatisticsQuestionAnswer();
    this.getStatisticsTrainList();
  }

  getStatisticalPreview() {
    // this.http.get(environment.api.home.getStatisticalPreview).subscribe((res: any) => {
    //   this.info = res.entity;
    // });
  }

  getStatisticsUserList() {
    if (this.StatisticsUserType == 3 && (!this.StatisticsUserTime[0] || !this.StatisticsUserTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsUserList}?type=${this.StatisticsUserType}&startTime=${this.datePipe.transform(this.StatisticsUserTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsUserTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['用户'] = val.y1;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['用户'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsUserList = dvv.rows;
    // });
  }

  getStatisticsProjectList() {
    if (this.StatisticsProjectType == 3 && (!this.StatisticsProjectTime[0] || !this.StatisticsProjectTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsProjectList}?type=${this.StatisticsProjectType}&startTime=${this.datePipe.transform(this.StatisticsProjectTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsProjectTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['项目'] = val.y1;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['项目'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsProjectList = dvv.rows;
    // });
  }

  getStatisticsMatchList() {
    if (this.StatisticsMatchType == 3 && (!this.StatisticsMatchTime[0] || !this.StatisticsMatchTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsMatchList}?type=${this.StatisticsMatchType}&startTime=${this.datePipe.transform(this.StatisticsMatchTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsMatchTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['比赛'] = val.y1;
    //     val['报名'] = val.y2;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['比赛', '报名'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsMatchList = dvv.rows;
    // });
  }

  getStatisticsArticleList() {
    if (this.StatisticsArticleType == 3 && (!this.StatisticsArticleTime[0] || !this.StatisticsArticleTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsArticleList}?type=${this.StatisticsArticleType}&startTime=${this.datePipe.transform(this.StatisticsArticleTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsArticleTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['资讯'] = val.y1;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['资讯'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsArticleList = dvv.rows;
    // });
  }

  getStatisticsInvest() {
    if (this.StatisticsInvestType == 3 && (!this.StatisticsInvestTime[0] || !this.StatisticsInvestTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsInvest}?type=${this.StatisticsInvestType}&startTime=${this.datePipe.transform(this.StatisticsInvestTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsInvestTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['投资人'] = val.y1;
    //     val['投资机构'] = val.y2;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['投资人', '投资机构'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsInvestList = dvv.rows;
    // });
  }

  getStatisticsQuestionAnswer() {
    if (this.StatisticsQuestionAnswerType == 3 && (!this.StatisticsQuestionAnswerTime[0] || !this.StatisticsQuestionAnswerTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsQuestionAnswer}?type=${this.StatisticsQuestionAnswerType}&startTime=${this.datePipe.transform(this.StatisticsQuestionAnswerTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsQuestionAnswerTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['提问'] = val.y1;
    //     val['回答'] = val.y2;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['提问', '回答'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsQuestionAnswerList = dvv.rows;
    // });
  }

  getStatisticsTrainList() {
    if (this.StatisticsTrainType == 3 && (!this.StatisticsTrainTime[0] || !this.StatisticsTrainTime[1])) {
      this.msg.error('请选择的时间');
      return;
    }
    // this.http.get(`${environment.api.home.getStatisticsTrainList}?type=${this.StatisticsTrainType}&startTime=${this.datePipe.transform(this.StatisticsTrainTime[0], 'yyyy/MM/dd')}&endTime=${this.datePipe.transform(this.StatisticsTrainTime[1], 'yyyy/MM/dd')}`).subscribe((res: any) => {
    //   res.entity.forEach(val => {
    //     val['培训'] = val.y1;
    //     val['参加培训'] = val.y2;
    //     val.x = val.x + ' ';
    //   });
    //   const dvv = new DataSet.View().source(res.entity);
    //   dvv.transform({
    //     type: 'fold',
    //     fields: ['培训', '参加培训'],
    //     key: 'xx',
    //     value: 'ces',
    //   });
    //   this.StatisticsTrainList = dvv.rows;
    // });
  }
}
