import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {_HttpClient, SettingsService} from '@delon/theme';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {environment} from "@env/environment";

@Component({
  selector: 'header-user',
  template: `
    <nz-dropdown nzPlacement="bottomRight">
      <div class="alain-default__nav-item d-flex align-items-center px-sm" nz-dropdown>
        <nz-avatar [nzSrc]="settings.user.avatar" nzSize="small" class="mr-sm"></nz-avatar>
        <!--{{settings.user.name}}-->
      </div>
      <div nz-menu class="width-sm">
        <div nz-menu-item>
          <p>{{settings.user.name}}</p>
          <p>{{settings.user.email}}</p>
        </div>
        <li nz-menu-divider></li>
        <div nz-menu-item (click)="logout()"><i class="anticon anticon-logout mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown>
  `,
})
export class HeaderUserComponent {
  constructor(
    public settings: SettingsService,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private http: _HttpClient,
  ) {
  }

  logout() {
    this.http.get(environment.api.common.logout).subscribe((res: any) => {
      this.tokenService.clear();
      this.router.navigateByUrl(this.tokenService.login_url);
    });
  }
}
