import {EventEmitter, Injectable} from '@angular/core';
import {_HttpClient} from "@delon/theme";
import {NzMessageService} from "ng-zorro-antd";
import {NzModalService} from "ng-zorro-antd";
import {Router, RoutesRecognized} from "@angular/router";

@Injectable()
export class CommonService {
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: NzModalService,
    private router: Router,
  ) {

  }

  hrefConfirmInit() {
    localStorage.setItem('hrefConfirm', '1');
    window.onbeforeunload = function (event) {
      var event = event || window.event;
      // 兼容IE8和Firefox 4之前的版本
      if (event) {
        event.returnValue = '确定要关闭窗口吗？';
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '确定要关闭窗口吗？';
    };
  }

  hrefConfirmUninstall() {
    localStorage.setItem('hrefConfirm', '0');
    window.onbeforeunload = function (event) {
      console.log('注销');
    };
  }
}
