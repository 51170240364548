import {Component, OnInit, ViewChild} from '@angular/core';
import {ControlWidget} from '@delon/form';
import {UEditorComponent} from "ngx-ueditor";

@Component({
  selector: 'sf-ueditor',
  template: `
    <sf-item-wrap [id]="id" [schema]="schema" [ui]="ui" [showError]="showError" [error]="error"
                  [showTitle]="schema.title">
      <ueditor
        #ueditor
        [ngModel]="value"
        [config]="config"
        [loadingTip]="loading"
        [delay]="delay"
        (ngModelChange)="change($event)">
      </ueditor>
    </sf-item-wrap>
  `,
  preserveWhitespaces: false,
  styles: [`:host ueditor {
    line-height: normal;
  }`],
})
// tslint:disable-next-line:component-class-suffix
export class UeditorWidget extends ControlWidget implements OnInit {
  static readonly KEY = 'ueditor';

  config: any;
  loading: string;
  delay: number;
  @ViewChild('ueditor') ueditor: UEditorComponent;

  ngOnInit(): void {
    this.loading = this.ui.loading || '加载中……';
    this.config = this.ui.config || {};
    this.delay = this.ui.delay || 300;
  }

  change(value: string) {
    if (value.indexOf('ueditor/themes/default/images/spacer.gif') > 0) {
      setTimeout(() => {
        this.change(this.ueditor.Instance.getContent());
      }, 500);
    } else {
      if (this.ui.change) this.ui.change(value);
      this.setValue(value);
    }
  }
}
