import { _HttpClient, SettingsService } from '@delon/theme';
import { Component, OnDestroy, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import {
  SocialService,
  SocialOpenType,
  TokenService,
  DA_SERVICE_TOKEN,
} from '@delon/auth';
import { ReuseTabService } from '@delon/abc';
import { environment } from '@env/environment.hmr';
import { StartupService } from '@core/startup/startup.service';

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [SocialService],
})
export class UserLoginComponent implements OnDestroy {
  form: FormGroup;
  error = '';
  type = 0;
  loading = false;

  constructor(
    fb: FormBuilder,
    private router: Router,
    public msg: NzMessageService,
    private modalSrv: NzModalService,
    private settingsService: SettingsService,
    private socialService: SocialService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: TokenService,
    private settingService: SettingsService,
    private startupSrv: StartupService,
    private http: _HttpClient,
  ) {
    // '18888888888'
    // '123456'
    this.form = fb.group({
      account: [null, [Validators.required,]],
      password: [null, Validators.required],
      mobile: [null, [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      captcha: [null, [Validators.required]],
      remember: [true],
      vcode: [null, [Validators.required]],
    });
    modalSrv.closeAll();
    this.getnum()
  }
  img: any

  getnum() {
    this.img = environment.api2.common.getVCode + '?' + Math.random()
  }
  // region: fields

  get userName() {
    return this.form.controls.account;
  }

  get password() {
    return this.form.controls.password;
  }

  get mobile() {
    return this.form.controls.mobile;
  }

  get captcha() {
    return this.form.controls.captcha;
  }
  get vcode() {
    return this.form.controls.vcode;
  }
  // endregion

  switch(ret: any) {
    this.type = ret.index;
  }

  // region: get captcha

  count = 0;
  interval$: any;

  getCaptcha() {
    this.count = 59;
    this.interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) clearInterval(this.interval$);
    }, 1000);
  }

  // endregion

  submit() {
    this.error = '';
    if (this.type === 0) {
      this.userName.markAsDirty();
      this.userName.updateValueAndValidity();
      this.password.markAsDirty();
      this.password.updateValueAndValidity();
      if (this.userName.invalid || this.password.invalid) return;

    } else {
      this.mobile.markAsDirty();
      this.mobile.updateValueAndValidity();
      this.captcha.markAsDirty();
      this.captcha.updateValueAndValidity();
      if (this.mobile.invalid || this.captcha.invalid) return;
    }

    const form = new FormData();
    form.append('username', this.userName.value);
    form.append('password', this.password.value);
    form.append('vcode', this.vcode.value);

    // 添加接口
    this.http.post(environment.api2.common.login, form).subscribe((res: any) => {
      this.loading = false;
      // this.msg.success(res.message);
      // 清空路由复用信息
      this.reuseTabService.clear();
      // 设置Token信息
      this.tokenService.set({
        token: '123456789',
        name: this.userName.value,
        time: +new Date(),
      });

      // "user": {
      //   "name": "Admin",
      //     "email": "cipchk@qq.com"
      // },

      const user: any = {};
      // user.avatar = res.entity.headImg;
      user.name = res.entity.username;
      // user.email = res.entity.account;
      this.settingService.setUser(user);

      // 重新获取 StartupService 内容，若其包括 User 有关的信息的话
      this.startupSrv.load().then((res2: any) => {
        this.router.navigate(['/']);
      });
      // 否则直接跳转
      // this.router.navigate(['/']);
    });

  }

  ngOnDestroy(): void {
    if (this.interval$) clearInterval(this.interval$);
  }
}
