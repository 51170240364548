import { CanDeactivate } from '@angular/router';

export class LeaveGuard implements CanDeactivate<any> {

  canDeactivate() {
    if (+localStorage.getItem('hrefConfirm')) {
      return window.confirm('确定要离开吗?');
    } else {
      return true;
    }
  }
}
