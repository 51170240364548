/**
 * 进一步对基础模块的导入提炼
 * 有关模块注册指导原则请参考：https://github.com/ng-alain/ng-alain/issues/180
 */
import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core';
import {throwIfAlreadyLoaded} from '@core/module-import-guard';

import {AlainThemeModule} from '@delon/theme';
import {DelonABCModule} from '@delon/abc';
import {DelonChartModule} from '@delon/chart';
import {DelonAuthModule} from '@delon/auth';
import {DelonACLModule} from '@delon/acl';
import {DelonCacheModule} from '@delon/cache';
import {DelonUtilModule} from '@delon/util';

// #region mock
import {DelonMockModule} from '@delon/mock';
import * as MOCKDATA from '../../_mock';
import {environment} from '@env/environment';

const MOCK_MODULES = !environment.production
  ? [DelonMockModule.forRoot({data: MOCKDATA})]
  : [];
// #endregion

// #region reuse-tab
/**
 * 若需要[路由复用](https://ng-alain.com/components/reuse-tab)需要：
 * 1、增加 `REUSETAB_PROVIDES`
 * 2、在 `src/app/layout/default/default.component.html` 修改：
 *  ```html
 *  <section class="alain-default__content">
 *    <reuse-tab></reuse-tab>
 *    <router-outlet></router-outlet>
 *  </section>
 *  ```
 */
import {RouteReuseStrategy} from '@angular/router';
import {ReuseTabService, ReuseTabStrategy} from '@delon/abc/reuse-tab';

const REUSETAB_PROVIDES = [
  // {
  //   provide: RouteReuseStrategy,
  //   useClass: ReuseTabStrategy,
  //   deps: [ReuseTabService],
  // },
];
// #endregion

// #region global config functions

import {PageHeaderConfig} from '@delon/abc';

export function fnPageHeaderConfig(): PageHeaderConfig {
  return Object.assign(new PageHeaderConfig(), {homeLink: '/admin', autoTitle: true, syncTitle: true});
}

import {DelonAuthConfig} from '@delon/auth';

export function fnDelonAuthConfig(): DelonAuthConfig {
  return Object.assign(new DelonAuthConfig(), <DelonAuthConfig>{
    login_url: '/passport/login',
    token_send_place: 'url',
    // ignores: [ /\/login/,/\/accountLogin/, /\/htmlLogin/, /assets\// ],
  });
}

import {STConfig} from '@delon/abc';
import {NgxImgModule} from 'ngx-img';
import {DelonFormConfig} from "@delon/form";

export function simpleTableConfig(): STConfig {
  return Object.assign(new STConfig(), <STConfig>{
    showPagination: true,
    req: {
      reName: {
        pi: 'page',
        ps: 'pageSize'
      },
    },
    res: {
      reName: {
        total: 'entity.total',
        list: 'entity.data'
      },
    },
  });
}

export function fnDelonFormConfig(): DelonFormConfig {
  return Object.assign(new DelonFormConfig(), <DelonFormConfig>{
    onlyVisual: true,
  });
}

const GLOBAL_CONFIG_PROVIDES = [
  // TIPS：@delon/abc 有大量的全局配置信息，例如设置所有 `st` 的页码默认为 `20` 行
  { provide: DelonFormConfig, useFactory: fnDelonFormConfig },
  {provide: STConfig, useFactory: simpleTableConfig},
  {provide: PageHeaderConfig, useFactory: fnPageHeaderConfig},
  {provide: DelonAuthConfig, useFactory: fnDelonAuthConfig},
];

// #endregion

@NgModule({
  imports: [
    AlainThemeModule.forRoot(),
    DelonABCModule.forRoot(),
    DelonChartModule.forRoot(),
    DelonAuthModule.forRoot(),
    DelonACLModule.forRoot(),
    DelonCacheModule.forRoot(),
    DelonUtilModule.forRoot(),
    NgxImgModule.forRoot(),
    // mock
    ...MOCK_MODULES,
  ],
})
export class DelonModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: DelonModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'DelonModule');
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DelonModule,
      providers: [...REUSETAB_PROVIDES, ...GLOBAL_CONFIG_PROVIDES],
    };
  }
}
