import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent, HttpHeaders,
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {mergeMap, catchError} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd';
import {_HttpClient} from '@delon/theme';
import {environment} from '@env/environment';

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {
  }

  get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private handleData(
    event: HttpResponse<any> | HttpErrorResponse,
  ): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    this.injector.get(_HttpClient).end();
    // 业务处理：一些通用操作
    switch (event.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        // 例如响应内容：
        //  错误内容：{ status: 1, msg: '非法参数' }
        //  正确内容：{ status: 0, response: {  } }
        // 则以下代码片断可直接适用
        if (event instanceof HttpResponse) {
          const body: any = event.body;
          if (body && !body.status) {
          } else if (event.url.indexOf('/Oss/signature.do') !== -1 || event.url.indexOf('aliyuncs.com') !== -1 || event.url.indexOf('/Setting/uploadApk.do') !== -1) {
            return of(event);
          } else if (body && body.status === 400) {
            this.goTo('/passport/login');
          } else if (body && body.status === 505) {
            if (body.message === '权限不足') {
              this.msg.info(body.message);
            } else {
              this.msg.error(body.message);
            }
            return throwError({});
          } else if (body && body.status === 500) {
            this.msg.error(body.message);
            return throwError({});
          } else if (body && body.status !== 200) {
            if (environment.production !== true) {
              this.msg.error(body.message);
            }
            return throwError({});
          } else {
            // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
            // return of(new HttpResponse(Object.assign(event, {body: body.entity})));
            // 或者依然保持完整的格式
            return of(event);
          }
        }
        break;
      case 304:
        break;
      case 400:
        this.msg.error('未知错误，请稍后再试');
        return throwError({});
        break;
      case 401: // 未登录状态码

        this.goTo('/passport/login');
        break;
      // case 403:
      case 404:
        this.msg.error(`错误404！`);
        // this.goTo(`/${event.status}`);
        break;
      case 500:
        this.msg.error(`错误500！`);
        // this.goTo(`/${event.status}`);
        break;
      default:
        if (event instanceof HttpErrorResponse) {
          console.warn(
            '未可知错误，大部分是由于后端不支持CORS或无效配置引起',
            event,
          );
          this.msg.error(event.message);
          // this.goTo('/passport/login');
        }
        break;
    }
    return of(event);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<| HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = environment.SERVER_URL + url;
    }
    let newReq;
    if (url.indexOf('/Oss/signature.do') !== -1 || url.indexOf('aliyuncs.com') !== -1) {
      newReq = req.clone({
        url: url,
        withCredentials: false
      });
    } else {
      newReq = req.clone({
        url: url,
        withCredentials: true
      });
    }
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理，这是因为一个请求若是业务上错误的情况下其HTTP请求的状态是200的情况下需要
        if (event instanceof HttpResponse && event.status === 200)
          return this.handleData(event);
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
}
