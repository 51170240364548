import {Injectable} from '@angular/core';
import {environment} from "@env/environment.hmr";
import {_HttpClient} from "@delon/theme";

export class OssParam {
  key: string;
  policy: string;
  OSSAccessKeyId: string;
  success_action_status = '200'; // 让服务端返回200,不然，默认会返回204
  callback: string;
  signature: string;
}
export class OssSignModel {
  policy: string;
  accessid: string;
  signature: string;
  host: string;
  expire: number;
  dir: string;
  callback: string;
}
@Injectable()
export class OssUploadService {

  ossSign: OssSignModel;
  params: OssParam;

  constructor(private  http: _HttpClient) {
  }

  // 获取签名
  private getSignature() {
    return this.http.get(environment.api2.common.signature)
      .toPromise().then((response) => {
          this.ossSign = response as OssSignModel;
          this.params = new OssParam();
          this.params.signature = this.ossSign.signature;
          this.params.policy = this.ossSign.policy;
          this.params.callback = this.ossSign.callback;
          this.params.key = this.ossSign.dir;
          this.params.OSSAccessKeyId = this.ossSign.accessid;
        });
  }

  private getSuffix(filename: string): string {
    let suffix = '';
    const pos = filename.lastIndexOf('.');
    if (pos !== -1) {
      suffix = filename.substring(pos);
    }

    // 产生随机数
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';
    const uuid = s.join('');
    return uuid + suffix;
  }

  // 上传文件
  fileUpload(file: any): Promise<string> {
    return this.getSignature().then(res => {
      const form = new FormData();
      // 随机文件名
      const now = new Date();
      const key = this.params.key + now.getTime() + this.getSuffix(file.name); // file.name;
      for (const k in this.params) {
        form.append(k, this.params[k]);
      }
      // 防止多次请求覆盖
      form.set('key', key);
      form.append(`file`, file);
      return this.http.post(this.ossSign.host, form).toPromise().then(rrs => {
        // 返回图片地址
        return `${this.ossSign.host}/${key}`;
      });
    });
  }
}
