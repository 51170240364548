import {Component, ViewChild} from '@angular/core';
import {_HttpClient, SettingsService} from '@delon/theme';
import {NzMessageService} from "ng-zorro-antd";
import {Router} from "@angular/router";
import {environment} from "@env/environment";

@Component({
  selector: 'layout-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  searchToggleStatus: boolean;
  msgCount = 0;

  constructor(public settings: SettingsService, private msg: NzMessageService, private http: _HttpClient,
              private router: Router) {
    this.getMessageList();
  }

  readMessage() {
    this.router.navigate([`/admin/message/list`]);
    this.msgCount = 0;
  }

  toggleCollapsedSidebar() {
    this.settings.setLayout('collapsed', !this.settings.layout.collapsed);
  }

  searchToggleChange() {
    this.searchToggleStatus = !this.searchToggleStatus;
  }

  getMessageList() {
    // this.http.get(`${environment.api.common.userInfo}`).subscribe((res: any) => {
    //   this.msgCount = res.entity.newsCount;
    // });
  }
}
